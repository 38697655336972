import React, { Component } from "react";
import AddToCartBtn from "./AddToCartBtn";
import { AppContext } from "../context";
import moment from "moment";

// init histroy and cartItem arrays
let cartItems = [];
let history = [];

// check local storage for cart items and push to cartItem array
if (localStorage.getItem("cart")) {
  const gotItems = JSON.parse(localStorage.getItem("cart"));
  gotItems.forEach(item => {
    cartItems.push(item);
  });
}

// check local storage for history items and push to history array
if (localStorage.getItem("history")) {
  const gotItems = JSON.parse(localStorage.getItem("history"));
  gotItems.forEach(item => {
    history.push(item);
  });
}

// empty both cartItem & history in case no local stogare found
function checkStorage() {
  if (!localStorage.getItem("cart")) {
    cartItems = [];
  }
  if (!localStorage.getItem("history")) {
    history = [];
  }
}

export default class AddToCart extends Component {
  // context api
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      data: "",
      period: "",
      dateAdded: ""
    };
  }

  // update cart onClick addtocart
  updateCart = () => {
    if (this.state.period !== "null" && this.state.period !== "") {
      this.setState(
        {
          id: this.props.data.id,
          data: this.props.data,
          dateAdded: moment()
            .subtract(10, "days")
            .calendar()
        },
        () => {
          let value = this.context;
          // check the storage
          checkStorage();
          // save this.state to local storage
          this.populateStorage();
          // check cart for changes in cartCount
          value.checkCart();
        }
      );
    } else {
      const timeInput = document.getElementById("cart__timePeriod");
      timeInput.classList.add("animateInput");
      setTimeout(() => {
        timeInput.classList.remove("animateInput");
      }, 500);
    }
  };

  // onChange period dropdown
  handlePeriodChange = e => {
    this.setState({ period: e.target.value });
  };

  // handleNegotiate = () => {
  //   this.setState({ negotiate: !this.state.negotiate });
  // };

  // save this.state in local storage
  populateStorage() {
    cartItems.push(this.state);
    history.push(this.state);
    localStorage.setItem("cart", JSON.stringify(cartItems));
    localStorage.setItem("history", JSON.stringify(history));
  }

  render() {
    return (
      <AddToCartBtn
        updateCart={this.updateCart}
        handlePeriodChange={this.handlePeriodChange}
        currentUserId={this.props.currentUserId}
      />
    );
  }
}

// default period prop
// AddToCart.defaultProps = {
//   period: ""
// };
