import React, { Component } from "react";
import { AppContext } from "../../context";
import { Link } from "react-router-dom";
import Breakpoint, { setDefaultBreakpoints } from "react-socks";
import ProfileIcon from "../ProfileIcon";

// import logo
import Logo from "../../images/logo.svg";

// set default breakpoints
setDefaultBreakpoints([{ xs: 0 }, { sm: 599 }, { md: 610 }]);

export default class Header extends Component {
  // context type
  static contextType = AppContext;

  render() {
    // context value
    let value = this.context;
    return (
      <React.Fragment>
        <div className="container-fluid header">
          {/* desktop header */}
          <Breakpoint md up>
            <header className="container header__wrap">
              <div className="col-lg-4 col-md-5 col-sm-5 brand">
                <div className="logo">
                  <a href="/" className="logo__img" style={backImg}>
                    &nbsp;
                  </a>
                  <span className="logo__text hide-on-xs">
                    Staff Augmentation
                  </span>
                </div>
              </div>
              <div className="col-lg-8 col-md-7 col-sm-7 nav">
                <ul className="nav__list">
                  <li>
                    <a href="tel:919901337558" className="nav__list-item">
                      <span className="icon-phone" />
                      <span className="hide-on-xs">{"(+91) 9901337558"}</span>
                    </a>
                  </li>
                  {value.isLoggedIn && (
                    <React.Fragment>
                      <li onClick={this.cartClick}>
                        <Link to="/cart" className="nav__list-item">
                          <span className="icon-cart" />
                          <strong className="hide-on-xs">{"Cart"}</strong>
                          {value.cartCount > 0 && (
                            <span className="nav__list-notification">
                              {value.cartCount}
                            </span>
                          )}
                        </Link>
                      </li>
                      <ProfileIcon />
                    </React.Fragment>
                  )}
                </ul>
              </div>
            </header>
          </Breakpoint>
          {/* mobile header */}
          <Breakpoint sm down>
            <header className="container header__wrap">
              {!value.isLoggedIn && (
                <div className="col-md-4 col-sm-5 brand">
                  <div className="logo">
                    <a href="/" className="logo__img" style={backImg}>
                      &nbsp;
                    </a>
                    <span className="logo__text">Digital Studio</span>
                  </div>
                </div>
              )}
              {value.isLoggedIn && (
                <div className="col-md-4 col-sm-5 nav nav-m">
                  <ul className="nav__list">
                    <React.Fragment>
                      <ProfileIcon />
                    </React.Fragment>
                  </ul>
                </div>
              )}
              <div className="col-md-8 col-sm-7 nav">
                <ul className="nav__list">
                  <li>
                    <a href="tel:919901337558" className="nav__list-item">
                      <span className="icon-phone" />
                      <span className="hide-on-xs">{"(+91) 9901337558"}</span>
                    </a>
                  </li>
                  {value.isLoggedIn && (
                    <React.Fragment>
                      <li onClick={this.cartClick}>
                        <Link to="/cart" className="nav__list-item">
                          <span className="icon-cart" />
                          <strong className="hide-on-xs">{"Cart"}</strong>
                          {value.cartCount > 0 && (
                            <span className="nav__list-notification">
                              {value.cartCount}
                            </span>
                          )}
                        </Link>
                      </li>
                    </React.Fragment>
                  )}
                </ul>
              </div>
            </header>
          </Breakpoint>
        </div>
      </React.Fragment>
    );
  }
}

// logo
var backImg = {
  backgroundImage: "url(" + Logo + ")"
};
