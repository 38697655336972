import React from "react";

// footer links with name and url
const footerLinks = [
  {
    name: "About Us",
    url: "/about-pattem/"
  },
  {
    name: "Services",
    url: "/services/"
  },
  {
    name: "Our Process",
    url: "/our-process/"
  },
  {
    name: "Case Studies",
    url: "/casestudy/"
  },
  {
    name: "Insights",
    url: "/insights/"
  },
  {
    name: "Careers",
    url: "/careers/"
  },
  {
    name: "Contact Us",
    url: "/contactus/"
  }
];

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__navigation">
        <div className="footer__navigation-sub col-xs-12 col-sm-5 col-md-4">
          <p>Signup with email address to receive news and update</p>
          <form className="subscribe">
            <input
              className="subscribe-input"
              type="email"
              placeholder="Sign up for our newsletter"
            />
            <button className="subscribe-button">
              <i className="icon-chevron-right" />
            </button>
          </form>
        </div>
        <div className="footer__navigation-links col-xs-12 col-sm-7 col-md-8">
          <ul className="clearfix">
            {footerLinks.map((link, idx) => {
              return (
                <li className="col-xs-4 col-md-2" key={idx}>
                  <a
                    href={`https://pattemdigital.com${link.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="footer__container">
        <div
          className="footer__legal-container"
          data-aos="slide-left"
          data-aos-duration={300}
          data-aos-offset={50}
        >
          <p>© 2019</p>
          <p>
            <a
              href="mailto:hello@pattemdigital.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@pattemdigital.com
            </a>
          </p>
          <p>
            <a href="tel:095-8346-6007">(095) 8346-6007</a>
          </p>
        </div>
        <nav
          className="footer__nav"
          data-aos="slide-right"
          data-aos-duration={300}
          data-aos-offset={50}
        >
          <ul className="footer__social-icons">
            <li>
              <a
                className="fb"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/PattemDigital/"
                title="facebook"
              >
                <i className="icon-facebook" />
              </a>
            </li>
            <li>
              <a
                className="twitter"
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/PattemDigital"
                title="twitter"
              >
                <i className="icon-twitter" />
              </a>
            </li>
            <li>
              <a
                className="linked-in"
                target="_blank"
                rel="noopener noreferrer"
                href="https://in.linkedin.com/company/pattem-digital-technologies-private-limited"
                title="linkedin"
              >
                <i className="icon-linkedin" />
              </a>
            </li>
            <li>
              <a
                className="instagram"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/pattemdigital/"
                title="instagram"
              >
                <i className="icon-instagram" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}
