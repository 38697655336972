import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { StateProvider } from "./context";
import App from "./App";

// import index.css including global styles
import "./index.css";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  // StateProvider for context provider
  <StateProvider>
    {/* Router for react-routing */}
    <Router>
      <App />
    </Router>
  </StateProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
