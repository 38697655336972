import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { AppContext } from "../context";

export default class LogInForm extends Component {
  // context api
  static contextType = AppContext;

  // initial state
  constructor(props) {
    super(props);
    this.state = {
      renderLogIn: false,
      forgotPassword: false,
      sentEmail: false,
      resetPage: false,
      resetted: false,
      fields: {},
      errors: {}
    };
  }

  // handle form changes
  handleFormChange = e => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  };

  // handle form submit
  handleFormSubmit = e => {
    e.preventDefault();

    // check if validate
    if (this.isValidate()) {
      this.setState({ renderLogIn: true });

      // if validated, then handleLogin and check the cart
      let value = this.context;
      value.handleLogIn();
      value.checkCart();
    }
  };

  // input validation
  isValidate = () => {
    let fields = this.state.fields;
    let errors = {};
    let isValid = true;

    // username
    if (!fields.username) {
      isValid = false;
      errors["username"] = "Please enter your email";
    } else if (typeof fields["username"] !== "undefined") {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["username"])) {
        isValid = false;
        errors["username"] = "Please enter a valid email";
      }
    }

    // password
    if (!fields["password"]) {
      isValid = false;
      errors["password"] = "Please enter your password";
    }

    this.setState({
      errors: errors
    });

    return isValid;
  };

  // .header styles changes after mount
  componentDidMount() {
    window.scrollTo(0, 0);
    document.querySelector(".header").classList.remove("header-purple");
    document.querySelector(".header").style.paddingTop = "0";
  }

  // if forgot password
  handleForgotPassword = () => {
    this.setState({ forgotPassword: !this.state.forgotPassword });
  };

  // if forgot password email sent
  handleSentEmail = () => {
    this.setState({
      sentEmail: true,
      forgotPassword: !this.state.forgotPassword
    });
  };

  // handle reset password
  handleResetPassword = () => {
    this.setState({
      sentEmail: false,
      forgotPassword: false
    });
  };

  // if reset seccessful
  handleResetted = () => {
    this.setState({ resetted: true });
  };

  render() {
    return (
      <div className="container signform__container">
        <div className="login-form">
          <div className="login-form__wrap">
            {!this.state.forgotPassword &&
              !this.state.sentEmail &&
              !this.state.resetted &&
              !this.state.resetPage && (
                <React.Fragment>
                  <form>
                    <div className="input-wrap">
                      <input
                        type="email"
                        name="username"
                        placeholder="Email ID"
                        onChange={this.handleFormChange}
                      />
                      <span className="err">{this.state.errors.username}</span>
                    </div>
                    <div className="input-wrap">
                      <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={this.handleFormChange}
                      />
                      <span className="err">{this.state.errors.password}</span>
                    </div>
                    <button
                      className="btn btn--orange btn--block"
                      onClick={this.handleFormSubmit}
                    >
                      Log In
                    </button>
                    <span
                      className="p login-form__help"
                      onClick={this.handleForgotPassword}
                    >
                      Forgot Password?
                    </span>
                  </form>
                  <p className="login-form__signup">
                    Need a new account?{" "}
                    <Link to="/signup">
                      <span>
                        <strong>Sign Up</strong>
                      </span>
                    </Link>
                  </p>
                </React.Fragment>
              )}
            {this.state.forgotPassword && (
              <React.Fragment>
                <div className="forgot">
                  <p className="forgot-head">Forgot Password?</p>
                  <p className="forgot-hint">
                    We will send you a link to reset your password.
                  </p>
                  <form className="forgot-form">
                    <span className="p">Enter Email Address</span>
                    <input type="email" placeholder="Email ID" />
                    <button
                      className="btn btn--orange btn--block"
                      onClick={this.handleSentEmail}
                    >
                      Send Reset Link
                    </button>
                  </form>
                  <p className="forgot-login">
                    <span onClick={this.handleForgotPassword}>Log In!</span>
                  </p>
                </div>
              </React.Fragment>
            )}
            {this.state.sentEmail && (
              <React.Fragment>
                <div className="forgot">
                  <p className="forgot-head">Forgot Password?</p>
                  <p className="forgot-head-sent">
                    We have sent a reset password link to your email account.
                  </p>
                </div>
              </React.Fragment>
            )}
            {this.state.resetPage && !this.state.resetted && (
              <React.Fragment>
                <form>
                  <input type="password" placeholder="Enter New Password" />
                  <input type="password" placeholder="Re-type New Password" />
                  <button
                    className="btn btn--orange btn--block"
                    onClick={this.handleResetted}
                  >
                    Change Password
                  </button>
                </form>
              </React.Fragment>
            )}
            {this.state.resetted && (
              <React.Fragment>
                <div className="forgot">
                  <p className="forgot-head-sent">
                    Your password got successfully updated
                  </p>
                  <p className="forgot-login">
                    <span>
                      <Link to="login">Log In!</Link>
                    </span>
                  </p>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        {this.state.renderLogIn && <Redirect to="/profiles" />}
      </div>
    );
  }
}
