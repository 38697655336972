import React, { Component } from "react";
import { AppContext } from "../context";
import { Link } from "react-router-dom";

export default class ProfileDropDown extends Component {
  //  context api
  static contextType = AppContext;

  // handle signout function
  handleSignOut = () => {
    let value = this.context;
    // handle signout function in context api
    value.handleSignOut();
  };

  render() {
    return (
      <div className="c-profile__dropdown x81">
        <div className="cd__wrap">
          <ul>
            <li>
              <Link to="/history">History</Link>
            </li>
            <li onClick={this.handleSignOut}>
              <Link to="/">Sign Out</Link>
            </li>
            <li>
              <Link to="/account">View Profile</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

window.onclick = function(event) {
  if (!event.target.matches(".c-profile__btn")) {
    var dropdowns = document.getElementsByClassName("x81");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show")) {
        openDropdown.classList.remove("show");
      }
    }
  }
};
