import React, { Component } from "react";
import { AppContext } from "../context";
import { Link } from "react-router-dom";
import { Breakpoint, BreakpointProvider } from "react-socks";
import DayPickerInput from "react-day-picker/DayPickerInput";
import Topbar from "./layouts/Topbar";
import CartItem from "./CartItem";

import "../stylesheets/vendor/_day-picker.scss";

export default class History extends Component {
  // context api
  static contextType = AppContext;

  // init states
  constructor(props) {
    super(props);
    this.state = { data: [], noItem: false, startDate: null, endDate: null };
  }

  // get history arr from before mounting
  componentWillMount() {
    let value = this.context;
    window.scroll(0, 0);

    if (value.isLoggedIn) {
      // check and get history array from local storage
      if (localStorage.getItem("history")) {
        const gotItems = JSON.parse(localStorage.getItem("history"));

        // filter only unique history items
        const uniqArr = gotItems.filter(
          (el, index, self) => index === self.findIndex(t => t.id === el.id)
        );

        // set uniqArr as this.state.data
        this.setState({ data: uniqArr });

        // update the local storage history array
        localStorage.setItem("history", JSON.stringify(uniqArr));

        // if array is empty set this.state.noItems to true
        if (gotItems.length === 0) {
          this.setState({ noItem: true });
        }
        //   console.log(uniqArr);
        //   localStorage.setItem("history", JSON.stringify(uniqArr));
      } else {
        this.setState({ noItem: true });
      }
    }
  }

  // onDayStart
  onDayStart = day => {
    this.setState({ startDate: day });
  };

  // onDayStart
  onDayEnd = day => {
    this.setState({ endDate: day });
  };

  // change header style after mount
  componentDidMount() {
    document.querySelector(".header").classList.add("header-purple");
  }

  // checkStorage = () => {
  //   if (this.state.data.length === 0) {
  //     this.setState({ noItem: true });
  //   }
  // };

  // handleRemove = e => {
  //   const deleteId = e.target.id;
  //   const oldArr = JSON.parse(localStorage.getItem("history"));
  //   const newArr = oldArr.filter(item => item.data.id.toString() !== deleteId);

  //   localStorage.setItem("history", JSON.stringify(newArr));
  //   this.setState({ data: newArr }, () => {
  //     this.checkStorage();
  //   });
  // };

  // handleClearHistory = () => {
  //   localStorage.setItem("history", `[]`);
  //   this.setState({ data: [], noItem: true });
  //   this.checkStorage();
  // };

  render() {
    // context api value
    let value = this.context;
    let isLoggedIn = value.isLoggedIn;

    return (
      <BreakpointProvider>
        {isLoggedIn && (
          <Breakpoint sm down>
            <Topbar title="History" />
          </Breakpoint>
        )}
        <div className="cart__container cart__history container">
          {isLoggedIn && (
            <React.Fragment>
              {this.state.data.length > 0 && (
                <div className="cart__head">
                  <div className="cart__head-l">
                    <p className="cart__head-lh">History</p>
                    <p>Lorem ipsum dolor sit amet, consectetuer</p>
                  </div>
                  <div className="cart__head-r-h">
                    <div className="cart__history-filter">
                      <DayPickerInput
                        onDayChange={day => this.onDayStart(day)}
                        placeholder="Start Date"
                      />
                      <DayPickerInput
                        onDayChange={day => this.onDayEnd(day)}
                        placeholder="Start Date"
                      />
                    </div>
                    <button
                      className="btn btn--purple"
                      // onClick={this.handleClearHistory}
                    >
                      Go
                    </button>
                  </div>
                </div>
              )}
              {this.state.data.map(i => (
                <CartItem key={"key_" + i.id} data={i} removeBtn={false} />
              ))}
              {this.state.data.length === 0 && this.state.noItem && (
                <div className="cart__messages">
                  <p>No History!</p>
                  <Link to="/profiles" className="btn btn--orange">
                    View Profiles
                  </Link>
                </div>
              )}
            </React.Fragment>
          )}
          {!isLoggedIn && (
            <div className="cart__messages">
              <Link to="/login">
                <button className="btn btn--orange">Please Log In</button>
              </Link>
            </div>
          )}
        </div>
      </BreakpointProvider>
    );
  }
}
