import React, { Component } from "react";
import { AppContext } from "../context";
import { Link } from "react-router-dom";
import Breakpoint, {
  setDefaultBreakpoints,
  BreakpointProvider
} from "react-socks";
import Topbar from "./layouts/Topbar";

import Pencil from "../images/pencil.png";

// set default breakpoints
setDefaultBreakpoints([
  { xs: 0 },
  { sm: 599 },
  { md: 610 },
  { tab: 780 },
  { tabu: 790 }
]);

export default class AccountDetail extends Component {
  static contextType = AppContext;

  // styles in .header after mounting
  componentDidMount() {
    document.querySelector(".header").classList.remove("header-purple");
    document.querySelector(".header").style.boxShadow = "none";
    window.scroll(0, 0);
  }

  render() {
    // context api values
    let value = this.context;
    let isLoggedIn = value.isLoggedIn;

    // destructuring value.user
    const {
      username,
      name,
      email,
      phone,
      website,
      address,
      company
    } = value.user;

    return (
      <BreakpointProvider>
        {isLoggedIn && (
          <Breakpoint sm down>
            <Topbar title="Your Account" />
          </Breakpoint>
        )}
        <div className="acc__container">
          {isLoggedIn && (
            <div className="acc__detail">
              <div className="acc__detail-head">
                <div className="container">
                  <div className="acc__detail-head-l">
                    <div className="acc__detail-head-avatar">
                      <span>{value.trimName}</span>
                    </div>
                    <div className="acc__detail-head-name">
                      <span className="acc__detail-head-h">{name}</span>
                      <span className="acc__detail-head-d">{company.name}</span>
                    </div>
                  </div>
                  <div className="acc__detail-head-r">
                    <Link to="/edit" className="acc__detail-edit">
                      <span>
                        <img src={Pencil} alt="Edit Profile" />
                      </span>
                      <span className="hide-on-xs">Edit Profile</span>
                    </Link>
                  </div>
                </div>
              </div>
              {/* tablet and up */}
              <Breakpoint tabu up>
                <div className="acc__detail-body">
                  <div className="container">
                    <div className="acc__detail-body-flex">
                      <table className="wrap">
                        <tbody>
                          <tr>
                            <td>Full Name:</td>
                            <td>{name}</td>
                          </tr>
                          <tr>
                            <td>Username:</td>
                            <td>{username}</td>
                          </tr>
                          <tr>
                            <td>Email:</td>
                            <td>{email}</td>
                          </tr>
                          <tr>
                            <td>Phone:</td>
                            <td>{phone}</td>
                          </tr>
                          <tr>
                            <td>Website:</td>
                            <td>{website}</td>
                          </tr>
                          <tr>
                            <td>Company Name:</td>
                            <td>{company.name}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="acc__detail-body-flex">
                      <table className="wrap">
                        <tbody>
                          <tr>
                            <td>City:</td>
                            <td>{address.city}</td>
                          </tr>
                          <tr>
                            <td>Street:</td>
                            <td>{address.street}</td>
                          </tr>
                          <tr>
                            <td>Suite:</td>
                            <td>{address.suite}</td>
                          </tr>
                          <tr>
                            <td>Zip Code:</td>
                            <td>{address.zipcode}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Breakpoint>
              {/* tablet down */}
              <Breakpoint tab down>
                <div className="acc__detail-body">
                  <div className="container">
                    <div className="acc__detail-body-flex">
                      <table className="wrap">
                        <tbody>
                          <tr>
                            <td>Full Name:</td>
                            <td>{name}</td>
                          </tr>
                          <tr>
                            <td>Username:</td>
                            <td>{username}</td>
                          </tr>
                          <tr>
                            <td>Email:</td>
                            <td>{email}</td>
                          </tr>
                          <tr>
                            <td>Phone:</td>
                            <td>{phone}</td>
                          </tr>
                          <tr>
                            <td>Website:</td>
                            <td>{website}</td>
                          </tr>
                          <tr>
                            <td>Company Name:</td>
                            <td>{company.name}</td>
                          </tr>
                          <tr>
                            <td>City:</td>
                            <td>{address.city}</td>
                          </tr>
                          <tr>
                            <td>Street:</td>
                            <td>{address.street}</td>
                          </tr>
                          <tr>
                            <td>Suite:</td>
                            <td>{address.suite}</td>
                          </tr>
                          <tr>
                            <td>Zip Code:</td>
                            <td>{address.zipcode}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Breakpoint>
            </div>
          )}
          {!isLoggedIn && (
            <div className="cart__messages">
              <Link to="/login">
                <button className="btn btn--orange">Please Log In</button>
              </Link>
            </div>
          )}
        </div>
      </BreakpointProvider>
    );
  }
}
