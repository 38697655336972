import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class SignForm extends Component {
  // styles change in .header after mounting
  componentDidMount() {
    document.querySelector(".header").classList.remove("header-purple");
    document.querySelector(".header").style.paddingTop = "0";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="container signform__container">
        <React.Fragment>
          <div className="signform__wrap">
            <div className="signform__btn">
              <Link to="/signup">
                <button className="btn btn--orange btn__log-cta">
                  Sign Up
                </button>
              </Link>
              <Link to="/login">
                <button className="btn btn--orange btn__log-cta">Log In</button>
              </Link>
            </div>
            <div className="signform__info">
              <p className="signform__info-heading">
                Lorem ipsum dolor sit amet
              </p>
              <p className="signform__info-para">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper. adipiscing elit, sed diam
                nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
                erat volutpat. Ut wisi enim ad minim
                <br />
                <br />
                veniam, quis nostrud exerci tation ullamcorper suscipit lobortis
                nisl ut aliquip ex ea commodo consequat. Duis autem vel eum
                iriure Lorem inim veniam, quis nostrud exerci tation ullamcorper
                suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis
              </p>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}
