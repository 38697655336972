import React, { Component } from "react";
import { Route } from "react-router-dom";

import Brand from "../../images/brand.png";

export default class Topbar extends Component {
  // set context type for react-router-dom goBack()
  static contextTypes = {
    router: () => null
  };

  render() {
    return (
      <div className="container topbar">
        <div className="col-xs-9">
          {!this.props.disable && (
            <Route
              render={({ history }) => (
                <span
                  className={this.props.icon}
                  onClick={() => {
                    // react-router history
                    history.goBack();
                  }}
                />
              )}
            />
          )}
          {this.props.homeIcon && (
            <a href="/" className="brand-icon__img left-home">
              <img src={Brand} alt="Brand Icon" />
            </a>
          )}
          <span className="topbar__nav">{this.props.title}</span>
        </div>
        <div className="col-xs-3 brand-icon">
          {!this.props.homeIcon && (
            <a href="/" className="brand-icon__img">
              <img src={Brand} alt="Brand Icon" />
            </a>
          )}
        </div>
      </div>
    );
  }
}

// default props for Topbar
Topbar.defaultProps = {
  icon: "icon-arrow-thin-left",
  disable: false
};
