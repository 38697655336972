import React, { Component } from "react";
import { Link } from "react-router-dom";

import ThumbMale from "../images/thumb-male.png";

export default class Profile extends Component {
  render() {
    // destructing from the prop - p
    const {
      id,
      name,
      username,
      email,
      company,
      address,
      website
    } = this.props.p;

    return (
      <div className="profile__box">
        <Link to={`/profiles/${id}`} className="profile__box-inner">
          <div className="profile__head">
            <div className="profile__head-l">
              <div className="profile__head-avatar">
                <img src={ThumbMale} alt="Avatar" />
              </div>
              <div>
                <span className="profile__name">{name}</span>
                <span className="profile__des">{email}</span>
                <span className="profile__com">{username}</span>
              </div>
            </div>
            <div className="profile__head-r">
              <span>Website: {website}</span>
              <span>City: {address.city}</span>
              <span>Zip Code: {address.zipcode}</span>
            </div>
          </div>
          <div className="profile__foot">
            <span>Company: {company.name}</span>
            <span>Business: {company.bs}</span>
            <span>Phrase: {company.catchPhrase}</span>
          </div>
        </Link>
      </div>
    );
  }
}
