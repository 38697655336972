import React, { Component } from "react";
import ProfileSingle from "./ProfileSingle.js";
import { AppContext } from "../context";
import { Link } from "react-router-dom";
import { Breakpoint, BreakpointProvider } from "react-socks";
import Topbar from "./layouts/Topbar";

export default class ProfileDetail extends Component {
  // context api
  static contextType = AppContext;

  // init states
  constructor(props) {
    super(props);
    this.state = { currentUser: [], currentUserId: "" };
  }

  // get a single profile with the ID
  getUserDetail = id => {
    this.fetchProfile = () => {
      const url = "https://jsonplaceholder.typicode.com/users/" + id;
      fetch(url)
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(res.status);
        })
        // set the response as this.state.currentUser
        .then(res =>
          this.setState({
            currentUser: res
          })
        );
    };
    // function call
    this.fetchProfile();
  };

  handleUserState = id => {
    // context api values
    let value = this.context;
    let isLoggedIn = value.isLoggedIn;

    if (isLoggedIn) {
      // if logged in get the profile from param id and update the state
      this.getUserDetail(id);
      this.setState({ currentUserId: id });
    }

    window.scrollTo(0, 0);
  };

  // before component mount
  componentWillMount() {
    // get react router link params
    const {
      match: { params }
    } = this.props;

    this.handleUserState(params.id);
  }

  componentWillReceiveProps(nextProps) {
    this.handleUserState(nextProps.match.params.id);
  }

  render() {
    // context api values
    let value = this.context;
    let isLoggedIn = value.isLoggedIn;

    return (
      <BreakpointProvider>
        {isLoggedIn && (
          <Breakpoint sm down>
            <Topbar title="Detail Profile" />
          </Breakpoint>
        )}
        <div className="profile__container">
          {isLoggedIn && (
            <ProfileSingle
              currentUser={this.state.currentUser}
              currentUserId={this.state.currentUserId}
            />
          )}
          {!isLoggedIn && (
            <div className="cart__messages">
              <Link to="/login">
                <button className="btn btn--orange">Please Log In</button>
              </Link>
            </div>
          )}
        </div>
      </BreakpointProvider>
    );
  }
}
