import React, { Component } from "react";

export const AppContext = React.createContext();

class StateProvider extends Component {
  // main context state
  state = {
    isLoggedIn: false,
    user: "",
    trimName: "",
    cartCount: 0,
    filterOptions: []
  };

  // get the account/user information
  getUser() {
    const id = 3;
    const url = "https://jsonplaceholder.typicode.com/users/" + id;
    fetch(url)
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.status);
      })
      // set user/account information
      .then(res =>
        this.setState({
          user: res
        })
      )
      // set the trimmed name
      .then(() => {
        if (this.state.user !== "") {
          const nameArr = this.state.user.name.split(" ");
          const newShort = (nameArr[0][0] + nameArr[1][0]).toUpperCase();

          this.setState({ trimName: newShort });
        }
      });
  }

  // handle log in (change the state and get user/account information)
  handleLogIn = () => {
    this.setState({ isLoggedIn: true }, () => {
      // callback
      this.getUser();
    });
  };

  // handle sign out setting states to initial state
  handleSignOut = () => {
    this.setState({
      isLoggedIn: false,
      user: "",
      trimName: ""
    });
  };

  // update cart count in the state
  handleCartCount = cart => {
    let count = cart.length;
    this.setState({ cartCount: count });
    // console.log(count);
  };

  // check cart from local storage
  checkCart = () => {
    if (localStorage.getItem("cart")) {
      let cartStorage = JSON.parse(localStorage.getItem("cart"));
      // handle cart count
      this.handleCartCount(cartStorage);
    } else {
      this.setState({ cartCount: 0 });
    }
  };

  // filter state change
  handleFilterChange = value => {
    this.setState({ filterOptions: [...this.state.filterOptions, value] });
  };

  // remove filter items
  handleFilterRemove = e => {
    let filterArr = this.state.filterOptions;
    let index = filterArr.indexOf(e);
    if (index > -1) {
      filterArr.splice(index, 1);
    }
    this.setState({ filterOptions: filterArr });
  };

  // filter clear
  handleFilterClear = () => {
    this.setState({ filterOptions: [] });
  };

  // set styles(responsive) after state change
  componentWillUpdate() {
    if (this.state.isLoggedIn && window.innerWidth < 599) {
      document.querySelector(".header").style.paddingTop = "64px";
    } else if (!this.state.isLoggedIn && window.innerWidth < 599) {
      document.querySelector(".header").style.paddingTop = "0";
    }
  }

  render() {
    return (
      // context provider
      <AppContext.Provider
        value={{
          ...this.state,
          checkCart: this.checkCart,
          handleLogIn: this.handleLogIn,
          handleSignOut: this.handleSignOut,
          handleFilterChange: this.handleFilterChange,
          handleFilterRemove: this.handleFilterRemove,
          handleFilterClear: this.handleFilterClear
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

const StateConsumer = AppContext.Consumer;

export { StateProvider, StateConsumer };
