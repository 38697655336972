import React from "react";

export default function Pagination() {
  return (
    <div className="container profile__list-pagination">
      <div className="profile__list-pagination--wrap">
        <button className="prev">
          <span className="icon-chevron-right" />
          <span>Previous</span>
        </button>
        <ul className="page">
          <li>
            <a href="/" className="active">
              1
            </a>
          </li>
          <li>
            <a href="/">2</a>
          </li>
          <li>
            <a href="/">3</a>
          </li>
        </ul>
        <button className="next">
          <span>Next</span>
          <span className="icon-chevron-right" />
        </button>
      </div>
    </div>
  );
}
