import React, { Component } from "react";
import { AppContext } from "../context";
import ProfileDropDown from "./ProfileDropDown";

export default class ProfileIcon extends Component {
  // context api
  static contextType = AppContext;

  // toggleShow
  toggleShow = () => {
    document.querySelector(".c-profile__dropdown").classList.toggle("show");
    document.querySelector(".c-profile__btn").classList.toggle("show");
  };

  render() {
    // context api value
    let value = this.context;

    return (
      <React.Fragment>
        <li className="c-profile">
          <span className="c-profile__wrap">
            <span className="c-profile__name">
              <span className="c-profile__name-t">{value.trimName}</span>
            </span>
            <button
              className="c-profile__btn icon-caret x81"
              onClick={this.toggleShow}
            />
          </span>
        </li>
        <ProfileDropDown />
      </React.Fragment>
    );
  }
}
