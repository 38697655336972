import React, { Component } from "react";
import { AppContext } from "../context";
import Profile from "./Profile";
import ProfileFilter from "./ProfileFilter";
import { BreakpointProvider } from "react-socks";
import Pagination from "./Pagination";

export default class ProfileList extends Component {
  // context api
  static contextType = AppContext;

  // initial state
  constructor(props) {
    super(props);
    this.state = {
      profiles: [],
      filterOpen: false
    };
  }

  // get all profiles
  getProfiles() {
    const url = "https://jsonplaceholder.typicode.com/users";
    fetch(url)
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.status);
      })
      .then(res => this.setState({ profiles: res }));
  }

  // before mounting component
  componentWillMount() {
    this.getProfiles();
    // document.addEventListener("keydown", e => this.escapeFilter(e));
  }

  // after mounting component
  componentDidMount() {
    window.scrollTo(0, 0);
    document.querySelector(".header").classList.add("header-purple");
  }

  // escapeFilter = e => {
  //   if (e.key === "Escape" || e.key === "Esc") {
  //     this.setState({ filterOpen: false });
  //   }
  // };

  // after component update
  componentDidUpdate() {
    // changing styles depending on filterOpen status
    if (this.state.filterOpen) {
      document.querySelector("body").style.overflow = "hidden";
      document.querySelector(".popup__wrapper").style = "";
    } else {
      document.querySelector("body").style = "";
      document.querySelector(".popup__wrapper").style.display = "none";
    }
  }

  // componentWillUnmount() {
  //   document.removeEventListener("keydown", e => this.escapeFilter(e));
  // }

  // handle filter option
  handleFilterOption = e => {
    let value = this.context;

    if (e.target.checked) {
      // pushing in filterOptions state
      value.handleFilterChange(e.target.name);
    } else {
      // removing from filterOptions state
      value.handleFilterRemove(e.target.name);
    }
  };

  // clear filter options
  clearFilterOptions = () => {
    let value = this.context;

    let checkBoxes = document.getElementsByClassName("filter-checkbox");
    for (let i = 0; i < checkBoxes.length; i++) {
      if (checkBoxes[i].type === "checkbox") {
        checkBoxes[i].checked = false;
      }
    }
    value.handleFilterClear();
  };

  // toggle filterOpen state
  toggleFilter = () => {
    this.setState({ filterOpen: !this.state.filterOpen });
  };

  render() {
    return (
      <React.Fragment>
        <BreakpointProvider>
          <ProfileFilter
            totalCount={this.state.profiles.length}
            toggleFilter={this.toggleFilter}
            handleFilterOption={this.handleFilterOption}
            clearFilterOptions={this.clearFilterOptions}
          />
        </BreakpointProvider>
        <div className="profile__list">
          <div className="container profile__list-container">
            {this.state.profiles.map(p => (
              <Profile p={p} key={p.id} className="profile__box" />
            ))}
          </div>
          <Pagination />
        </div>
      </React.Fragment>
    );
  }
}
