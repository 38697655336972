import React, { Component } from "react";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import Breakpoint, { setDefaultBreakpoints } from "react-socks";

import Remove from "../images/close.png";
import SearchIcon from "../images/search.svg";

// set default breakpoints
setDefaultBreakpoints([{ xs: 0 }, { sm: 599 }, { md: 610 }]);

// filter array
const fliterArr = [
  {
    id: 1,
    tab: "Services",
    items: [
      "Strategy Consulting",
      "UX Research Experience",
      "Game Development",
      "IoT Development"
    ]
  },
  {
    id: 2,
    tab: "Designation",
    items: [
      "Business Analyst",
      "Database Administrator",
      "Network Administrator",
      "Product Manager"
    ]
  },
  {
    id: 3,
    tab: "Skills",
    items: [
      "Node.js",
      "Web Development",
      "JavaScript Frameworks",
      "React.js Development",
      "PHP Development"
    ]
  },
  {
    id: 4,
    tab: "Location",
    items: [
      "Agartala",
      "Agra",
      "Ahmedabad",
      "Ahmednagar",
      "Aizawal",
      "Ajmer",
      "Alappuzha",
      "Aligarh",
      "Allahabad",
      "Arunachal",
      "Baddi",
      "Bahrain",
      "Bangladesh",
      "Bangalore",
      "Bhagalpur"
    ]
  },
  {
    id: 5,
    tab: "Notice Period",
    items: ["Immediate", "15 Days", "1 Month", "2 month", "3 Month"]
  },
  {
    id: 6,
    tab: "Organisation",
    items: [
      "Cognizant Technology Solutions",
      "Accenture",
      "Infosys",
      "Capgemini",
      "Tata Consultancy Services",
      "IBM",
      "HCL Technologies",
      "TCS",
      "Wipro",
      "Tech Mahindra",
      "Oracle",
      "Mindtree"
    ]
  },
  {
    id: 7,
    tab: "Mandate Skill",
    items: [
      "Node.js Developer",
      "Web Development",
      "PHP Development",
      "Java Development",
      "Sitecore Development",
      ".Net Development",
      "AEM Development",
      "SAP Development",
      "LAMP Development",
      "Ruby On Rails Development",
      "DevOps As A Service",
      "Blockchain technology",
      "Cognos Business Intelligence",
      "React JS Development",
      "MERN Stack Development",
      "React Native Development",
      "JavaScript Developer",
      "Graphic Designer",
      "UI/UX Researcher",
      "Java Springboot Development",
      "C# Web Developer"
    ]
  }
];

export default class FilterPopup extends Component {
  render() {
    return (
      <div className="popup__wrapper" style={{ display: "none" }}>
        <div className="popup__wrapper-inner">
          <Breakpoint md up>
            <span
              className="popup__wrapper-close"
              onClick={this.props.toggleFilter}
            >
              <img src={Remove} alt="close filter" />
            </span>
          </Breakpoint>
          <Breakpoint sm down>
            <span
              className="popup__wrapper-close popup__wrapper-close-m"
              onClick={this.props.clearFilterOptions}
            >
              Clear all
            </span>
          </Breakpoint>
          <Tabs className="popup__wrapper-flex">
            <div className="popup__wrapper-left">
              <div className="popup__wrapper-left-cat">
                <p className="filter-by">Filter By</p>
                {fliterArr.map(item => {
                  return (
                    <TabLink to={item.tab} key={"ID:" + item.id}>
                      {item.tab}
                    </TabLink>
                  );
                })}
              </div>
              <button
                className="clear-filter"
                onClick={this.props.clearFilterOptions}
              >
                Clear all
              </button>
            </div>
            <div className="popup__wrapper-right">
              {fliterArr.map(item => {
                return (
                  <TabContent for={item.tab} key={"ID:" + item.id}>
                    <div className="popup__wrapper-items">
                      <p>{item.tab}</p>
                      <input
                        type="text"
                        placeholder="Search"
                        className="filter_input"
                        style={{ backgroundImage: "url(" + SearchIcon + ")" }}
                      />
                      <div className="popup__wrapper-items-wrap clearfix">
                        {item.items.map((t, index) => {
                          return (
                            <span className="inline-checkbox" key={"ID:" + t}>
                              <input
                                type="checkbox"
                                id={index + t}
                                name={t}
                                className="filter-checkbox"
                                onChange={this.props.handleFilterOption}
                              />
                              <span />
                              <label htmlFor={index + t}>{t}</label>
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </TabContent>
                );
              })}
            </div>
          </Tabs>
          <Breakpoint sm down>
            <div className="popup__wrapper-xs">
              <br />
              <button onClick={this.props.toggleFilter}>Close</button>
              <button onClick={this.props.toggleFilter}>Apply</button>
            </div>
          </Breakpoint>
        </div>
      </div>
    );
  }
}
