import React from "react";

export default function GetInTouch() {
  return (
    <div className="contact">
      <div className="contact__wrapper container">
        <div className="contact__text">
          <h2>Just want to drop a ‘Hello’</h2>
        </div>
        <div className="contact__btn">
          <button className="btn btn--hollow">Contact</button>
        </div>
      </div>
    </div>
  );
}
