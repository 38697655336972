import React, { Component } from "react";
import { AppContext } from "../context";
import { Link } from "react-router-dom";
import { Breakpoint, BreakpointProvider } from "react-socks";
import ProfileList from "./ProfileList";
import Testimonial from "./layouts/Testimonial";
import GetInTouch from "./layouts/GetInTouch";
import Topbar from "./layouts/Topbar";

export default class ProfilesContainer extends Component {
  // context api
  static contextType = AppContext;

  // before mounting component
  componentWillMount() {
    // context api values
    let value = this.context;
    // check cart in context api for any changes
    value.checkCart();
    window.scroll(0, 0);
  }

  render() {
    // context api values
    let value = this.context;
    let isLoggedIn = value.isLoggedIn;

    return (
      <BreakpointProvider>
        {isLoggedIn && (
          <Breakpoint sm down>
            <Topbar
              title="Candidate Profiles"
              icon="icon-home"
              disable={true}
              homeIcon={true}
            />
          </Breakpoint>
        )}
        <div className="profile__container">
          {isLoggedIn && (
            <React.Fragment>
              <ProfileList />
              <Testimonial />
              <GetInTouch />
            </React.Fragment>
          )}
          {!isLoggedIn && (
            <div className="cart__messages">
              <Link to="/login">
                <button className="btn btn--orange">Please Log In</button>
              </Link>
            </div>
          )}
        </div>
      </BreakpointProvider>
    );
  }
}
