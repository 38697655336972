import React from "react";
import Slider from "react-slick";

import Face1 from "../../images/face.jpeg";
import Face2 from "../../images/face-1.jpeg";

export default function Testimonial() {
  // react-slick settings
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 6000
  };

  return (
    <div className="slider-contain multiple">
      <div className="container">
        <Slider className="slider slick" {...settings}>
          <div className="slider-single">
            <div className="container">
              <div className="row slider">
                <div className="slider-img col-xs-7 col-md-5 col-lg-4">
                  <div className="slider-img__wrap">
                    <img src={Face1} alt="Glera Charlotte" />
                  </div>
                </div>
                <div className="slider-content col-xs-12 col-md-6 col-lg-7">
                  <div className="slider-video__wrapper">
                    <div>
                      <h3 className="slider-content__name">Glera Charlotte</h3>
                      <span className="slider-content__designation">
                        Chief Operating Officer
                      </span>
                    </div>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                    quis nostrud exerci tation
                  </p>
                  <span className="slider-content__company-name">Nvidia</span>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-single">
            <div className="container">
              <div className="row slider">
                <div className="slider-img col-xs-7 col-md-5 col-lg-4">
                  <div className="slider-img__wrap">
                    <img src={Face2} alt="Lily Collins" />
                  </div>
                </div>
                <div className="slider-content slider-video col-xs-12 col-md-6 col-lg-7">
                  <div className="slider-video__wrapper">
                    <div>
                      <h3 className="slider-content__name">Lilly Collins</h3>
                      <span className="slider-content__designation">
                        Managing Director
                      </span>
                    </div>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                    quis nostrud exerci tation
                  </p>
                  <span className="slider-content__company-name">
                    Intel Corporation
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
