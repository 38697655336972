import React, { Component } from "react";
import { Link } from "react-router-dom";

import ThumbMale from "../images/thumb-male.png";
import Remove from "../images/close.png";

export default class CartItem extends Component {
  render() {
    // destructure data prop value
    const { id, data, period } = this.props.data;

    return (
      <div className="cart__item">
        <div className="cart__item-wrap">
          <div className="cart__flex cart__flex-1">
            {/* <div className="cart__item-date">
              <span>10 Jun</span>
            </div> */}
            <div className="cart__item-avatar">
              <img src={ThumbMale} alt="Avatar" />
            </div>
          </div>
          <div className="cart__flex cart__flex-2">
            <span className="h">{data.name}</span>
            <span className="d">{data.email}</span>
            <span className="c">{data.username}</span>
          </div>
          <div className="cart__flex cart__flex-3">
            <span>Website: {data.website}</span>
            <span>Location: {data.address.city}</span>
            <span>Zip Code: {data.address.zipcode}</span>
          </div>
          <div className="cart__flex cart__flex-4">
            <span className="e">Expenses : Rs. 95,000 per Month</span>
            <span className="t">Exclusive Tax</span>
            {window.innerWidth > 599 && window.innerWidth <= 768 && (
              <div className="cart__flex-5">
                <span>For {period}</span>
              </div>
            )}
          </div>
          <div className="cart__flex cart__flex-5">
            <span>For {period}</span>
          </div>
        </div>
        {this.props.removeBtn && (
          <span
            className="cart__item-remove"
            onClick={this.props.handleRemove}
            id={id}
            style={{ backgroundImage: "url(" + Remove + ")" }}
          />
        )}
        <Link className="cart__item-view" id={id} to={`/profiles/${id}`}>
          View Profile
        </Link>
      </div>
    );
  }
}
