import React, { Component } from "react";
import { AppContext } from "../context";
import { Link } from "react-router-dom";
import { Breakpoint, BreakpointProvider } from "react-socks";
import Topbar from "./layouts/Topbar";

import ArrIcon from "../images/caret.svg";

export default class AccountEdit extends Component {
  static contextType = AppContext;

  // style changes in .header after mounting
  componentDidMount() {
    document.querySelector(".header").classList.remove("header-purple");
    window.scroll(0, 0);
  }

  render() {
    // context api values
    let value = this.context;
    let isLoggedIn = value.isLoggedIn;

    return (
      <BreakpointProvider>
        {isLoggedIn && (
          <Breakpoint sm down>
            <Topbar title="Edit Your Account" />
          </Breakpoint>
        )}
        <div className="acc__container">
          {isLoggedIn && (
            <React.Fragment>
              <div className="signup-form">
                <div className="signup-form__wrap">
                  <form className="clearfix">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <input type="text" placeholder="First Name" />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <input type="text" placeholder="Last Name" />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <input type="text" placeholder="Designation" />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <select
                        className="select-input"
                        style={{ backgroundImage: "url(" + ArrIcon + ")" }}
                      >
                        <option defaultValue="Work Location">
                          Work Location
                        </option>
                        <option value="Bangalore">Bangalore</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Mumbai">Mumbai</option>
                      </select>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <input type="text" placeholder="Company Name" />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <input type="text" placeholder="Company Email Address" />
                    </div>
                    <div className="col-xs-12">
                      <input type="text" placeholder="Company Website URL" />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <input type="text" placeholder="Contact Number 1" />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <input type="text" placeholder="Contact Number 2" />
                    </div>
                    <div className="col-xs-12">
                      <input type="text" placeholder="LinkedIn URL" />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <input type="password" placeholder="Password" />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <input type="password" placeholder="Password" />
                    </div>
                    <div className="col-xs-12 signup-form__submit">
                      <Link to="/account">
                        <button className="btn btn--orange">
                          Save Changes
                        </button>
                      </Link>
                      <Link to="/account">
                        <button className="btn btn--orange">Cancel</button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </React.Fragment>
          )}
          {!isLoggedIn && (
            <div className="cart__messages">
              <Link to="/login">
                <button className="btn btn--orange">Please Log In</button>
              </Link>
            </div>
          )}
        </div>
      </BreakpointProvider>
    );
  }
}
