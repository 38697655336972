import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { BreakpointProvider } from "react-socks";
import { AppContext } from "./context";

// import the main stylesheet
import "./stylesheets/main.scss";

// import all the components for routing
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import SignForm from "./components/SignForm";
import SignUpForm from "./components/SignUpForm";
import LogInForm from "./components/LogInForm";
import ProfilesContainer from "./components/ProfilesContainer";
import ProfileDetail from "./components/ProfileDetail";
import SignedUp from "./components/SignedUp";
import Cart from "./components/Cart";
import History from "./components/History";
import AccountDetail from "./components/AccountDetail";
import AccountEdit from "./components/AccountEdit";

class App extends Component {
  // context api
  static contextType = AppContext;

  render() {
    let value = this.context;
    let isLoggedIn = value.isLoggedIn;

    return (
      <React.Fragment>
        <div className="App">
          <div className="App-content">
            <BreakpointProvider>
              <Header />
            </BreakpointProvider>
            {/* react router switch */}
            <Switch>
              <Route exact path="/" component={SignForm} />
              <Route exact path="/signed" component={SignedUp} />
              <Route exact path="/signup" component={SignUpForm} />
              <Route exact path="/login" component={LogInForm} />
              <Route exact path="/profiles" component={ProfilesContainer} />
              <Route exact path="/profiles/:id" component={ProfileDetail} />
              <Route exact path="/cart" component={Cart} />
              <Route exact path="/history" component={History} />
              <Route exact path="/account" component={AccountDetail} />
              <Route exact path="/edit" component={AccountEdit} />
            </Switch>
          </div>
          {isLoggedIn ? <Footer /> : null}
        </div>
      </React.Fragment>
    );
  }
}

export default App;
