import React, { Component } from "react";
import FilterPopup from "./FilterPopup";
import { AppContext } from "../context";
import Breakpoint, { setDefaultBreakpoints } from "react-socks";

import FilterIcon from "../images/filter.svg";
import SearchIcon from "../images/search.svg";
import Remove from "../images/close.png";

// set default breakpoints
setDefaultBreakpoints([{ xs: 0 }, { sm: 599 }, { md: 610 }]);

export default class ProfileFilter extends Component {
  // context api
  static contextType = AppContext;

  render() {
    // context api value
    let value = this.context;
    let filterOptions = [...value.filterOptions];
    return (
      <React.Fragment>
        <div className="profile__filter">
          {/* filter: desktop */}
          <Breakpoint md up>
            <div className="profile__filter-wrap container">
              <div className="profile__filter-wrap-l">
                <input
                  type="text"
                  placeholder="Search"
                  className="filter_input"
                  style={{ backgroundImage: "url(" + SearchIcon + ")" }}
                />
                <button
                  className="btn btn--hollow-orange"
                  onClick={this.props.toggleFilter}
                >
                  <img src={FilterIcon} alt="filter-icon" /> Filter
                </button>
              </div>
              <div className="profile__filter-wrap-r">
                <span className="inline-checkbox">
                  <input type="checkbox" id="checkBox" />
                  <span />
                  <label htmlFor="checkBox">
                    {"All (" + this.props.totalCount + ")"}
                  </label>
                </span>
                <span className="inline-checkbox">
                  <input type="checkbox" id="checkBox1" />
                  <span />
                  <label htmlFor="checkBox1">
                    {"Saved in cart (" + value.cartCount + ")"}
                  </label>
                </span>
              </div>
            </div>
          </Breakpoint>
          {/* filter: mobile */}
          <Breakpoint sm down>
            <div className="profile__filter-wrap container">
              <div className="profile__filter-wrap-l">
                <input
                  type="text"
                  placeholder="Search"
                  className="filter_input filter_input-m"
                  style={{ backgroundImage: "url(" + SearchIcon + ")" }}
                />
              </div>
              <div className="profile__filter-wrap-r">
                <div>
                  <span className="inline-checkbox">
                    <input type="checkbox" id="checkBox" />
                    <span />
                    <label htmlFor="checkBox">
                      {"All (" + this.props.totalCount + ")"}
                    </label>
                  </span>
                  <span className="inline-checkbox">
                    <input type="checkbox" id="checkBox1" />
                    <span />
                    <label htmlFor="checkBox1">
                      {"Saved in cart (" + value.cartCount + ")"}
                    </label>
                  </span>
                </div>
                <button
                  className="btn btn--hollow-orange"
                  onClick={this.props.toggleFilter}
                >
                  <img src={FilterIcon} alt="filter-icon" /> Filter
                </button>
              </div>
            </div>
          </Breakpoint>
          <FilterPopup
            toggleFilter={this.props.toggleFilter}
            handleFilterOption={this.props.handleFilterOption}
            clearFilterOptions={this.props.clearFilterOptions}
          />
        </div>
        {filterOptions.length > 0 && (
          <div className="profile__filter-options">
            <div className="container">
              <div className="option-wrap">
                {filterOptions.map((item, index) => {
                  return (
                    <span key={"ID:" + index} className="option">
                      {item}
                    </span>
                  );
                })}
              </div>
              <div>
                <Breakpoint md up className="clear-btn">
                  <span onClick={this.props.clearFilterOptions}>Clear All</span>
                </Breakpoint>
                <Breakpoint sm down className="clear-btn">
                  <span
                    className="cart__item-remove"
                    onClick={this.props.clearFilterOptions}
                    style={{ backgroundImage: "url(" + Remove + ")" }}
                  />
                </Breakpoint>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
