import React, { Component } from "react";
import { AppContext } from "../context";
import { Link } from "react-router-dom";
import { Breakpoint, BreakpointProvider } from "react-socks";

import Topbar from "./layouts/Topbar";
import CartItem from "./CartItem";
import Remove from "../images/close.png";

export default class Cart extends Component {
  // context api
  static contextType = AppContext;

  // initial state
  constructor(props) {
    super(props);
    this.state = { data: [], submitted: false };
  }

  // check & get cart items after local storage
  componentDidMount() {
    // init cart array
    let cart = [];

    // check, get and push from local storage to the cart array
    if (localStorage.getItem("cart")) {
      const gotItems = JSON.parse(localStorage.getItem("cart"));
      gotItems.forEach(item => {
        cart.push(item);
      });
    }

    // set data state as the cart array
    this.setState({ data: cart, submitted: false });

    // change header styles after mount
    document.querySelector(".header").classList.add("header-purple");
    window.scroll(0, 0);
  }

  // handle submitted pop-up
  componentDidUpdate() {
    // context api value
    let value = this.context;

    // display.hide the pop-up
    if (value.isLoggedIn) {
      if (this.state.data.length === 0 && this.state.submitted) {
        document.querySelector("body").style.overflow = "hidden";
        document.querySelector(".popup__wrapper").style = "";
      } else {
        document.querySelector("body").style = "";
        document.querySelector(".popup__wrapper").style.display = "none";
      }
    }
  }

  // handle remove from cart function
  handleRemove = e => {
    // get the id of the item which we need to delete
    const dltId = e.target.id;

    // get the cart array from local storage
    const oldArr = JSON.parse(localStorage.getItem("cart"));

    // create new array EXCLUDING the item we need to delete
    const newArr = oldArr.filter(item => item.data.id.toString() !== dltId);

    // set current arr as the cart arr in local storage & also in the state (data)
    localStorage.setItem("cart", JSON.stringify(newArr));
    this.setState({ data: newArr });

    // check the cart for any changes
    let value = this.context;
    value.checkCart();

    // FIX
    // clear the whole array for the last item in the array
    if (oldArr.length === 1) {
      this.clearCart();
    }
  };

  // submit function clearing cart and change the state (submitted)
  submitCart = () => {
    this.clearCart();
    this.setState({ data: [], submitted: true });
  };

  // clear the cart
  clearCart = () => {
    localStorage.setItem("cart", []);
    // check the cart for changes in cartCount
    let value = this.context;
    value.checkCart();
  };

  // handle pop-up for submit function
  handlePopupClose = () => {
    this.setState({ data: [], submitted: false });
  };

  render() {
    let value = this.context;
    let isLoggedIn = value.isLoggedIn;
    return (
      <BreakpointProvider>
        {isLoggedIn && (
          <Breakpoint sm down>
            <Topbar title="Cart" />
          </Breakpoint>
        )}
        <div className="cart__container container">
          {isLoggedIn && (
            <React.Fragment>
              {this.state.data.length > 0 && (
                <div className="cart__head cart__head-c">
                  <div className="cart__head-l">
                    <p className="cart__head-lh">Cart</p>
                    <p>Lorem ipsum dolor sit amet, consectetuer</p>
                  </div>
                  <div className="cart__head-r cart__head-r-c">
                    <button
                      className="btn btn--purple"
                      onClick={this.submitCart}
                    >
                      Submit for response
                    </button>
                    <br />
                    <span className="inline-checkbox">
                      <input
                        type="checkbox"
                        id="neg"
                        // onChange={this.handleNegotiate}
                      />
                      <span />
                      <label htmlFor="neg">I want to negotiate</label>
                    </span>
                  </div>
                </div>
              )}
              {this.state.data.map(i => (
                <CartItem
                  key={"key_" + i.id}
                  data={i}
                  handleRemove={this.handleRemove}
                  removeBtn={true}
                />
              ))}
              {this.state.data.length === 0 && !this.state.submitted && (
                <div className="cart__messages">
                  <p>No items in your cart!</p>
                  <Link to="/profiles" className="btn btn--orange">
                    Browse Profiles
                  </Link>
                </div>
              )}
              <div
                className="popup__wrapper popup__wrapper-submitted"
                style={{ display: "none" }}
              >
                <div className="popup__wrapper-inner popup__wrapper-inner-submitted">
                  <span
                    className="popup__wrapper-close"
                    onClick={this.handlePopupClose}
                  >
                    <img src={Remove} alt="close filter" />
                  </span>
                  <div className="submitted-inner">
                    <div className="signed-up__wrap">
                      <div className="img">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="211.1"
                          height="157.698"
                          viewBox="0 0 211.1 157.698"
                        >
                          <g
                            id="Group_3845"
                            data-name="Group 3845"
                            transform="translate(-587 -329)"
                          >
                            <g
                              id="Group_3844"
                              data-name="Group 3844"
                              transform="translate(555.822 256.293)"
                            >
                              <g id="Group_3843" data-name="Group 3843">
                                <rect
                                  id="Rectangle_2571"
                                  data-name="Rectangle 2571"
                                  width="136.998"
                                  height="94.824"
                                  rx="23.309"
                                  transform="translate(106.314 72.707) rotate(7.042)"
                                  fill="#f7f7f7"
                                />
                                <g id="Group_3842" data-name="Group 3842">
                                  <g id="Group_3841" data-name="Group 3841">
                                    <g id="Group_3838" data-name="Group 3838">
                                      <g id="Group_3836" data-name="Group 3836">
                                        <path
                                          id="Path_1922"
                                          data-name="Path 1922"
                                          d="M39.106,174.465a8.952,8.952,0,0,0,2.048,17.786l8.893-1.024L48,173.441Z"
                                          fill="#f6c198"
                                        />
                                        <path
                                          id="Path_1923"
                                          data-name="Path 1923"
                                          d="M42.729,177.34a5.681,5.681,0,0,0,1.3,11.288l5.644-.65-1.3-11.288"
                                          fill="#ee927a"
                                        />
                                      </g>
                                      <g id="Group_3837" data-name="Group 3837">
                                        <path
                                          id="Path_1924"
                                          data-name="Path 1924"
                                          d="M123.041,164.8a8.952,8.952,0,0,1,2.048,17.786l-8.893,1.024-2.048-17.787Z"
                                          fill="#f6c198"
                                        />
                                        <path
                                          id="Path_1925"
                                          data-name="Path 1925"
                                          d="M120.166,168.425a5.681,5.681,0,0,1,1.3,11.288l-5.644.65-1.3-11.288Z"
                                          fill="#ee927a"
                                        />
                                      </g>
                                    </g>
                                    <rect
                                      id="Rectangle_2572"
                                      data-name="Rectangle 2572"
                                      width="76.92"
                                      height="105.19"
                                      rx="36.169"
                                      transform="translate(37.325 125.904) rotate(-6.567)"
                                      fill="#f6c198"
                                    />
                                    <g id="Group_3839" data-name="Group 3839">
                                      <path
                                        id="Path_1926"
                                        data-name="Path 1926"
                                        d="M106.187,186.749a22.991,22.991,0,1,1-45.681,5.259Z"
                                        fill="#02233b"
                                      />
                                      <path
                                        id="Path_1927"
                                        data-name="Path 1927"
                                        d="M102.9,201.481a22.993,22.993,0,0,1-35.849,4.127,22.979,22.979,0,0,1,35.849-4.127Z"
                                        fill="#c94038"
                                      />
                                    </g>
                                    <g id="Group_3840" data-name="Group 3840">
                                      <path
                                        id="Path_1928"
                                        data-name="Path 1928"
                                        d="M70.76,171.854a6.246,6.246,0,0,1-12.41,1.429"
                                        fill="none"
                                        stroke="#02233b"
                                        strokeLinecap="round"
                                        strokeMiterlimit={10}
                                        strokeWidth="1.972"
                                      />
                                      <path
                                        id="Path_1929"
                                        data-name="Path 1929"
                                        d="M104.032,168.024a6.246,6.246,0,0,1-12.41,1.428"
                                        fill="none"
                                        stroke="#02233b"
                                        strokeLinecap="round"
                                        strokeMiterlimit={10}
                                        strokeWidth="1.972"
                                      />
                                    </g>
                                    <path
                                      id="Path_1930"
                                      data-name="Path 1930"
                                      d="M87.156,183.663l-8.817,1.015-.632-15.511a3.3,3.3,0,0,1,2.92-3.412h0a3.3,3.3,0,0,1,3.619,2.659Z"
                                      fill="#ee927a"
                                    />
                                  </g>
                                  <path
                                    id="Path_1931"
                                    data-name="Path 1931"
                                    d="M99.255,121.054a37.075,37.075,0,0,0-4.174-2.2A38.46,38.46,0,0,0,40.8,153.68l-.275.031,2.332,20.254a15.811,15.811,0,0,0,13.906-17.43l1.36,11.81a42.32,42.32,0,0,0,27.939-15.217,41.664,41.664,0,0,0,3.092-4.268,33.655,33.655,0,0,0,3.187,3.545,32.734,32.734,0,0,0,26.493,8.951l-1.364-11.844A38.378,38.378,0,0,0,99.255,121.054Z"
                                    fill="#2c1349"
                                  />
                                </g>
                                <path
                                  id="Path_1932"
                                  data-name="Path 1932"
                                  d="M133.018,188.665s23.066,5.683,22.552-17.161l20.931,2.461S166.274,208.29,133.018,188.665Z"
                                  fill="#f7f7f7"
                                />
                              </g>
                            </g>
                            <text
                              id="THANK_YOU"
                              data-name="THANK YOU"
                              transform="translate(668.319 364.09) rotate(7)"
                              fill="#2c1349"
                              fontFamily="poppinsbold"
                              fontSize={20}
                              fontWeight={800}
                            >
                              <tspan x={0} y={21}>
                                THANK YOU
                              </tspan>
                            </text>
                          </g>
                        </svg>
                      </div>
                      <p>
                        Signed up successfully. Lorem ipsum dolor sit amet,
                        consectetuer adipiscing elit, sed diam nonummy nibh
                        euismod.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {!isLoggedIn && (
            <div className="cart__messages">
              <Link to="/login">
                <button className="btn btn--orange">Please Log In</button>
              </Link>
            </div>
          )}
        </div>
      </BreakpointProvider>
    );
  }
}
