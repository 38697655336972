import React, { Component } from "react";
import { Link } from "react-router-dom";

import ArrIcon from "../images/input-arr.png";

export default class AddToCartBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addedToCart: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (localStorage.getItem("cart")) {
      const gotItems = JSON.parse(localStorage.getItem("cart"));

      if (gotItems.length > 0 && nextProps.currentUserId) {
        let cartIds = [];

        gotItems.forEach(element => {
          cartIds.push(element.id.toString());
        });

        if (cartIds.includes(nextProps.currentUserId)) {
          this.setState({ addedToCart: true });
        } else {
          this.setState({ addedToCart: false });
        }
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {!this.state.addedToCart && (
          <React.Fragment>
            <select
              className="btn btn--hollow"
              id="cart__timePeriod"
              onChange={this.props.handlePeriodChange}
              style={styling}
            >
              <option value="null"> Time Period </option>
              <option value="3 Months">3 Months</option>
              <option value="6 Months">6 Months</option>
              <option value="9 Months">9 Months</option>
              <option value="12 Months">12 Months</option>
              <option value="18 Months">18 Months</option>
              <option value="2.5 Years">2.5 Years</option>
              <option value="3 Years">3 Years</option>
            </select>
            <button className="btn btn--white" onClick={this.props.updateCart}>
              Add to cart
            </button>
            <div className="profile__s-head-r-ins">
              <span>Select time period to add candidate in your cart</span>
            </div>
          </React.Fragment>
        )}
        {this.state.addedToCart && (
          <React.Fragment>
            <Link to="/profiles" className="btn btn--orange">
              View All
            </Link>
            <Link to="/cart" className="btn btn--white">
              Go to cart
            </Link>
            <span>Added to the cart successfully</span>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

// dropdown arrow icon
const styling = {
  backgroundImage: "url(" + ArrIcon + ")"
};
