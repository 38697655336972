import React, { Component } from "react";
import { Link } from "react-router-dom";

import ArrIcon from "../images/caret.svg";

export default class SignUpForm extends Component {
  // .header styles after component component
  componentDidMount() {
    document.querySelector(".header").classList.remove("header-purple");
    document.querySelector(".header").style.paddingTop = "0";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="container signform__container">
        <div className="signup-form">
          <div className="signup-form__wrap">
            <p className="signup-form__ins">Please fill in the details</p>
            <form className="clearfix">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <input type="text" placeholder="First Name" />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <input type="text" placeholder="Last Name" />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <input type="text" placeholder="Designation" />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <select
                  className="select-input"
                  style={{ backgroundImage: "url(" + ArrIcon + ")" }}
                >
                  <option defaultValue="Work Location">Work Location</option>
                  <option value="Bangalore">Bangalore</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Mumbai">Mumbai</option>
                </select>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <input type="text" placeholder="Company Name" />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <input type="text" placeholder="Company Email Address" />
              </div>
              <div className="col-xs-12">
                <input type="text" placeholder="Company Website URL" />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <input type="text" placeholder="Contact Number 1" />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <input type="text" placeholder="Contact Number 2" />
              </div>
              <div className="col-xs-12">
                <input type="text" placeholder="LinkedIn URL" />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <input type="password" placeholder="Password" />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <input type="password" placeholder="Password" />
              </div>
            </form>
            <div className="col-xs-12 signup-form__submit">
              <Link to="/signed">
                <button className="btn btn--orange">Sign Up</button>
              </Link>
            </div>
            <p className="signup-form__login">
              Already have an account?{" "}
              <Link to="/login">
                <span>
                  <strong>Log In</strong>
                </span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
