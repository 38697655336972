import React, { Component } from "react";
import AddToCart from "./AddToCart";
import Profile from "./Profile";
import Breakpoint, { setDefaultBreakpoints } from "react-socks";

import ThumbMale from "../images/thumb-male.png";

// set default breakpoints
setDefaultBreakpoints([{ xs: 0 }, { sm: 599 }, { md: 610 }]);

export default class ProfileSingle extends Component {
  // initial state
  constructor(props) {
    super(props);
    this.state = {
      profiles: []
    };
  }

  // get suggested profiles
  getSuggestedProfiles() {
    const url = "https://jsonplaceholder.typicode.com/users/";
    fetch(url)
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.status);
      })
      // set response as this.state.profiles
      .then(res => this.setState({ profiles: res }));
  }

  componentWillMount() {
    // call function
    this.getSuggestedProfiles();
  }

  // styles of .header after mounting the component
  componentDidMount() {
    window.scrollTo(0, 0);
    document.querySelector(".header").classList.remove("header-purple");
    document.querySelector(".header").style.boxShadow = "none";
  }

  render() {
    // destructure the currentUser in props.currentUser
    const { name, username, email, phone } = this.props.currentUser;

    return (
      <React.Fragment>
        <div className="profile__s">
          <div className="profile__s-head">
            <div className="container profile__s-head-wrap">
              <div className="profile__s-head-l">
                <div className="profile__s-avatar">
                  <img src={ThumbMale} alt="Profile Avatar" />
                </div>
                <div>
                  <span className="name">{name}</span>
                  <span className="des">{email}</span>
                  <span className="com">{username}</span>
                  <Breakpoint md up>
                    <span className="cost">
                      Expenses : Rs. 95,000 per Month
                    </span>
                    <span className="cost-exl">Exclusive Tax</span>
                  </Breakpoint>
                </div>
              </div>
              <Breakpoint sm down className="profile__s-head-l">
                <span className="cost">Expenses : Rs. 95,000 per Month</span>
                <span className="cost-exl">Exclusive Tax</span>
              </Breakpoint>
              <div className="profile__s-head-r">
                <AddToCart
                  data={this.props.currentUser}
                  currentUserId={this.props.currentUserId}
                />
              </div>
            </div>
          </div>
          <div className="container profile__s-body">
            <div className="p-body__wrap">
              <div className="p-body__h">About Me</div>
              <div className="p-body__b">
                <div className="p-body__b-f">
                  <ul>
                    <li>Name: {name}</li>
                    <li>Current Employer : Samsung</li>
                    <li>Dates Employed : Jan 2019 – Present</li>
                    <li>Employment Duration : 5 Mon</li>
                    <li>Location : Bengaluru Area, India</li>
                  </ul>
                </div>
                <div className="p-body__b-f">
                  <ul>
                    <li>Full Stack Developer</li>
                    <li>Phone: {phone}</li>
                    <li>Experience : 3 Years</li>
                    <li>Technologies : React.js, Node.js, Angular</li>
                    <li>Notice Period: 1 Month</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="p-body__wrap">
              <div className="p-body__h">Qualification</div>
              <div className="p-body__b">
                <div className="p-body__b-f">
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                    quis nostrud exerci tation ullamcorper suscipit lobortis
                    nisl ut aliquip ex ea commodo consequat.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-body__wrap">
              <div className="p-body__h">Awards &amp; Recognition</div>
              <div className="p-body__b">
                <div className="p-body__b-f">
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Unde, nam. Adipisci quidem libero ullam perspiciatis optio
                    necessitatibus, eaque provident error et repellat voluptas
                    eos assumenda molestiae officia numquam recusandae ipsam ex,
                    maiores dolor cupiditate. Voluptatibus quasi debitis
                    reprehenderit iste aperiam quidem! Placeat expedita rem
                    accusantium suscipit et sit, modi sed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* relevant profiles */}
        <div className="profile__list">
          <div className="container profile__list-container">
            <h3 className="rel-head">Recommended Profiles</h3>
            {this.state.profiles.map(p => (
              <Profile p={p} key={p.id} className="profile__box" />
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
